import { Controller } from "@hotwired/stimulus"
import PickerController from "./picker_controller"

export default class MultiChipPickerController extends PickerController {
  static targets = [...PickerController.targets, 'query', 'selectedContainer', 'selectedTemplate',
                    'selectedList', 'selectedOptionTemplate', 'selectedOptions', 'resultsFrame']

  static values = { url: String, minLength: Number, debounceTimer: Number, selected: Array, allowNew: Boolean}

  connect() {
    super.connect()
    this.selected = this.selectedValue || []
    this.render()
  }

  inputHandler(event) {
    event.preventDefault()
    event.target.value = event.target.value.substr(0, 50)
    event.target.value = event.target.value.replace(/[^a-zA-Z0-9,\/\&\(\)\- ]/g, "")

    if (this.allowNewValue && (event.target.value.includes(",") || event.key == "Enter")) {
      const [item, ...rest] = event.target.value.split(",").map(it => it.trim()).filter(it => it)
      event.target.value = rest;
      if (!item || item.length == 0) return;
      this.add(item, item)
      this.inputHandler(event)
    } else {
      this.debounce(this.debouncedInputHandler.bind(this, event), 500)
    }
  }

  search() {
    this.resultsFrameTarget.src = this.urlValue.replace(/%s/g, encodeURIComponent(this.queryTarget.value))
    this.showElement(this.resultsContainerTarget)
  }

  select(event) {
    event.preventDefault()
    this.add(event.params.id, event.params.value, event.params.additionalValues)
    this.selectedOptionsTarget.dispatchEvent(new Event('change'))
    this.queryTarget.form.dispatchEvent(new Event('change'))
    this.queryTarget.value = ""
    this.render()
  }

  clear(event) {
    this.remove(event.params.id)
    this.selectedOptionsTarget.dispatchEvent(new Event('change'))
    this.queryTarget.form.dispatchEvent(new Event('change'))
    event.preventDefault()
  }

  renderSelectedItem(id, label, additionalValues) {
    let item = this.selectedTemplateTarget.content.cloneNode(true);
    item.querySelector(".chip-box").setAttribute("data-id", id)
    item.querySelector(".chip-box").setAttribute("data-additional-values", JSON.stringify(additionalValues))
    item.querySelector('.chip-box').setAttribute("data-label", label);
    item.querySelector('.chip-label').innerText = label;
    item.querySelector('.remove-button').dataset.multiChipPickerIdParam = id;
    this.selectedListTarget.appendChild(item);
    let option = this.selectedOptionTemplateTarget.content.cloneNode(true);
    option.querySelector('option').value = id;
    option.querySelector('option').innerText = label;
    this.selectedOptionsTarget.appendChild(option);
  }

  add(id, label, additionalValues) {
    const idExists = this.selected.find(selected => selected.id == id)
    const labelExists = this.selected.find(selected => selected.label == label)
    if(idExists || labelExists) return

    this.selected.push({ id: id, label: label, additional_values: additionalValues })
    this.render()
  }

  remove(id) {
    this.selected = this.selected.filter(selected => selected.id != id)
    this.selectedOptionsTarget.querySelector(`option[value="${id}"]`).remove()
    this.selectedListTarget.querySelector(`.chip-box[data-id="${id}"]`).remove()
  }

  render() {
    this.selectedListTarget.innerHTML = ""
    this.selectedOptionsTarget.querySelectorAll('option')?.forEach(option => option.remove())
    this.selected.forEach(selected => {
      this.renderSelectedItem(selected.id, selected.label, selected.additional_values)
    })
    this.hideElement(this.resultsContainerTarget)
    this.queryTarget.focus()
    this.selectedOptionsTarget.dispatchEvent(new Event('change'))
  }
}
